@import "../../assets/styles/global_variable.scss";

.landing_logo {
  .logo_name {
    font-family: $logo_font;
    font-style: italic;
    font-weight: 300;
    display: inline;
    line-height: .7em;


    span {
      font-weight: 700;
      font-style: normal;
    }
  }
}