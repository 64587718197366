.container {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    height: 100vh;
    background: url('https://i.imgur.com/PsjPzdO.png');
    background-size: 200px;
    mix-blend-mode: color-dodge;
    position: absolute;
    top: 0;
    left: 0;
    // opacity: 0;
  }

  .blobs {
    width: 50vh;
    height: 50vh;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // filter: contrast(30);

    .blob {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      position: absolute;
      background-color: #fbfbfb;
      mix-blend-mode: multiply;
      filter: blur(80px);

      &.a {
        background-color: mix(#fbfbfb, white, 80%);
        animation: circular2 40s linear infinite;
      }

      &.b {
        background-color: mix(#fbfbfb, white, 50%);
        animation: circular 20s linear infinite;
      }

      &.c {
        animation: circular 60s linear infinite;
      }
    }
  }
}

$R: 20px;

@keyframes circular {
  0% {
    transform: translate(0, -#{$R});
  }

  25% {
    transform: translate(#{$R}, 0);
  }

  50% {
    transform: translate(0, #{$R});
  }

  75% {
    transform: translate(-#{$R}, 0);
  }

  100% {
    transform: translate(0, -#{$R});
  }
}

@keyframes circular2 {
  0% {
    transform: translate(0, #{$R});
  }

  25% {
    transform: translate(-#{$R}, 0);
  }

  50% {
    transform: translate(0, -#{$R});
  }

  75% {
    transform: translate(#{$R}, 0);
  }

  100% {
    transform: translate(0, #{$R});
  }
}