@import '../../assets/styles/global_variable.scss';

.footer_container {
  height: auto;
  gap: 20px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: $font_xs;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-wrap: nowrap;
  }

  .site {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-wrap: nowrap
  }
}