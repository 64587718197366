@mixin thin-transparent-scroll {

  // Webkit Browsers (Chrome, Safari)
  &::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    /* Transparent background */
    border-radius: 4px;
    /* Rounded edges */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
    /* Slightly darker on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    /* Transparent track */
  }

  // Firefox
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;

  // Edge and IE
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@mixin invisible-scroll {

  // Webkit Browsers (Chrome, Safari)
  &::-webkit-scrollbar {
    width: 0px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    /* Transparent background */
    border-radius: 0px;
    /* Rounded edges */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
    /* Slightly darker on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    /* Transparent track */
  }

  // Firefox
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;

  // Edge and IE
  -ms-overflow-style: -ms-autohiding-scrollbar;
}