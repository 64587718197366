@import '../../../assets/styles/global_variable.scss';

.line {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 10px;
  margin-bottom: 10px;
  text-wrap: nowrap;
  position: relative;

  .error {
    position: absolute;
    margin-top: 0px;
  }

  @media (max-width:520px) {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .input_dot {
    border: 0px !important;
    background-color: transparent !important;
    border-bottom: 2px dashed $border_color !important;
    border-radius: 0px !important;
    padding: 0px !important;
    height: max-content;
    line-height: 0.8em !important;
    box-shadow: none !important;
    font-size: 3em;

    @media (max-width:860px) {
      font-size: 2.5em;
    }


    @media (max-width:710px) {
      font-size: 2em;
    }

    @media (max-width:630px) {
      font-size: 1.5em;
    }


    &::placeholder {
      color: #a2a2a2;
      font-family: "Poppins", sans-serif;;
      font-size: 1em;
      padding-left: 10px !important;
    }

    &:focus {
      border-bottom: 2px dashed $border_color;
      background: transparent !important;
    }
  }
}