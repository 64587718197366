@import './assets/styles/global_variable.scss';
@import './assets/styles/mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');



html {
  scroll-behavior: smooth;
  @include thin-transparent-scroll;
}


body {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  margin: 0px;
  height: 100%;
  @include thin-transparent-scroll;
}

.error {
  margin-top: 10px;
  color: #EB5757;
  font-size: $font_sm;
  font-style: italic;
}

.submit_msg_1 {
  font-size: $font_lg;
  font-style: italic;
  font-weight: 400;
}

.form {


  label {
    color: #000;
    line-height: 2.5;
    font-weight: 500;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

// Keyframes for sliding out to the left
@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

input,
textarea {
  padding: 15px !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  border: 2px solid $border_color_dark !important;


  &:focus {
    border: 2px solid $border_color_dark !important;
    box-shadow: $box_shadow;
  }
}

.chakra-switch {
  box-shadow: none !important;



  .chakra-switch__track[aria-checked=true],
  .chakra-switch__track[data-checked],
  .chakra-switch__track[data-state=checked] {
    background-color: black !important;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
}

a {
  font-weight: 500;
}



.markdown {

  ul,
  ol {
    margin-left: 15px;
  }
}

.chakra-select {
  background-color: rgba(255, 255, 255, 0.3) !important;
  box-shadow: none !important;
  border: 2px solid $border_color_dark !important;
  border-radius: 10px !important;
}


.chakra-button:focus,
.chakra-select:focus,
.chakra-select__wrapper:focus,
.chakra-modal__close-btn:focus {
  box-shadow: none !important;
}

.shadow_text {
  text-shadow: -1px 0px 5px rgba(255, 255, 255, 0.8);
}

.shadow_text_dark {
  text-shadow: -1px 0px 5px rgba(0, 0, 0, 0.8);
}


.font_cursive {
  font-family: $cursive_font !important;
}

.font_cursive2 {
  font-family: $logo_font !important;
}

.font_playfair {
  font-family: "Playfair", serif;
}

.font_cormorant {
  font-family: "Cormorant", serif;
}

.app-scroll {
  @include thin-transparent-scroll;
}

.invisible-scroll {
  @include invisible-scroll;
}

.font_poppins {
  font-family: "Poppins", sans-serif;
}

.font_gloria {
  font-family: "Gloria Hallelujah", cursive;
}

.font_bebas {
  font-family: "Bebas Neue", sans-serif;
}