@import'../../assets/styles/global_variable.scss';

.main_wrapper {
  background: #F6F8ED;

  .react-colorful{
    width: 100% !important;
  }

  #about,
  #objectives {
    padding-top: 150px;
    /* Same as the height of your fixed header */
    margin-top: -10px;
    /* Adjusts for the height so that the section appears correctly below the header */
  }


  .small_heading {
    font-size: $font_lg;
    font-weight: 500;
  }

  .conversation_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .card_wrapper {
      padding: 20px 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: auto;
      column-gap: 20px;

      @media (max-width: 620px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */



      .quote {
        color: #fff;
        font-family: $cursive_font;
        font-style: italic;
        font-weight: 400;
        font-size: $font_2xl;
        cursor: default;
      }

      .quote_by {
        color: #fff;
        margin-top: 10px;
        font-size: $font_sm;
        cursor: default;
      }
    }
  }

  .section_template {
    margin: 40px 0px 60px 0px;
    padding: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    @media (max-width:620px) {
      padding: 10px;
      flex-direction: column;
      row-gap: 10px;
    }

    .bundle_title {
      width: 50%;
      margin-top: -28px;

      #star {
        margin-bottom: 10px;
      }

      @media (max-width:820px) {
        width: 20%;
      }

      .title {
        font-size: $font_lg;
        font-weight: 500;
        text-wrap: nowrap;
      }
    }

    .content_center {
      margin-top: 10px;
      font-size: $font_2xl;
    }

    .join {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      align-items: center;

      p {
        font-size: $font_2xl;
      }

      .social {
        cursor: pointer;
      }
    }

    .content {
      width: 50%;
      max-width: 500px;
      font-size: $font_2xl;

      .numbered_card {
        margin: 10px 0 30px 0px;

        .title {
          font-size: $font_sm;
          font-weight: 500;
        }

        .detail {
          font-size: $font_2xl
        }
      }

      @media (max-width:820px) {
        width: 80%;
        max-width: 80%;
      }

      @media (max-width:620px) {
        width: 100%;
      }
    }

  }

  .big_text_container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
    padding: 50px 30px 50px 30px;
    min-height: 70vh;

    @media (max-width:620px) {
      padding: 50px 10px 50px 10px;
    }
  }

  .mail {
    padding: 0px 30px;
    font-size: $font_2xl;
    margin-bottom: 40px;

    a {
      text-decoration: underline;
    }

    @media (max-width:620px) {
      padding: 0px 10px;

    }
  }

  .subscribe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    padding: 0px 30px;

    .title {
      text-align: center;
      font-weight: 500;
      font-size: $font_xl;
      line-height: 1em;
    }

    .detail {
      text-align: center;
      font-size: $font_md;
      line-height: 1.4em;
    }

    input {
      max-width: 400px;
    }

  }
}