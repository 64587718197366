/* CircleTextWheel.css */
.circle-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.circle-svg {
  width: 100%;
  height: 100%;
  animation: rotateWheel 10s linear infinite;
}

.circle-text {
  font-size: 13.5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.center-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Adjust the size of the icon as needed */
  color: #000; /* Adjust the color of the icon as needed */
}

/* Rotating wheel animation */
@keyframes rotateWheel {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
