@import '../../assets/styles/global_variable.scss';


.portfolio_container {
  padding: 20px;
  width: auto;
  height: calc(100dvh);
  background: #F6F8ED;


  .message_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .message {
      position: relative;

      #star {
        position: absolute;
        top: -90px;

        @media (max-width:630px) {
          height: 40px;
          width: 40px;
          top: -50px;
        }
      }

      .message_para {
        margin-top: 10px;
        font-size: 16px;
        text-align: left;
        line-height: 1.6em;

        .link_block {
          margin-bottom: -6px;
          position: relative;
          display: inline-block;
          text-wrap: nowrap;
          padding: 0px 2px;
          text-decoration: underline;
          color: #000;
          /* Adjust this to your desired default link color */
          overflow: hidden;

          &:hover {
            color: #fff;
            /* Text color on hover */
          }

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 100%;
            background-color: #000000;
            transition: all 0.4s ease;
            z-index: -1;
            transform: translate(-50%, -50%);
          }

          &:hover::before {
            width: 100%;
          }
        }
      }

      .message_highlight {
        text-align: left;
        line-height: 1.2em;
        font-size: 3em;
        padding-bottom: 10px;



        @media (max-width:860px) {
          font-size: 2.5em;
          padding-bottom: 9px;
        }


        @media (max-width:710px) {
          font-size: 2em;
          padding-bottom: 7px;
        }

        @media (max-width:630px) {
          font-size: 1.5em;
          padding-bottom: 6px;
        }

      }

      #next_action {
        position: absolute;
        flex-direction: row;
        display: flex;
        font-weight: 500;
        margin-top: 100px;
        margin-right: 10px;
        column-gap: 5px;
        font-size: $font_md;
        right: 0;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}