.profile_container {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 10px;
  min-width: 1160px;
  padding-bottom: 30px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    min-width: 100%;
  }

  @media (max-width:820px) {}
}