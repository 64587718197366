@import "../../assets/styles/global_variable.scss";


.wrap_all {
  width: 100%;
  position: fixed;
  top: 20px;
  left: 0px;
  right: 0px;

  .header_wrapper {
    width: 80%;
    margin: auto;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    line-height: 1em;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    max-width: 1000px;

    @media (max-width: 768px) {
      width: calc(100% - 20px);
    }


    .header_content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 10px;
      align-items: center;
      padding: 10px 30px;

      @media (max-width: 768px) {
        padding: 10px 10px;
      }
    }

    .other_menu {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      position: relative;
      /* Ensure relative positioning for pseudo-element */

      @media (max-width: 400px) {
        gap: 10px;
      }

      p {
        text-wrap: nowrap;
        font-weight: 500;
        font-size: $font_md;
        word-spacing: 0em;
        line-height: 1.2em;
        position: relative;
        /* Ensure the pseudo-element is positioned relative to this element */
        display: inline-block;
        /* Ensure it takes only the space it needs */

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 2px;
          /* Thickness of the underline */
          background-color: currentColor;
          /* Use the text color */
          transition: all 0.3s ease;
          /* Smooth transition effect */
          transform: translateX(-50%);
          /* Center the pseudo-element horizontally */
        }

        &:hover {
          cursor: pointer;

          &::after {
            width: 100%;
            /* Extend the underline to full width on hover */
          }
        }
      }

      .hidable {
        @media (max-width: 520px) {
          display: none;
        }
      }
    }

    .active {
      background-color: #D1D2D5;
      border-radius: 6px;
      padding: 2px;
    }
  }
}