@import'../../assets/styles/global_variable.scss';

.Landing_container {
  height: 100dvh;
  display: flex;
  padding: 20px 20px 0px 20px;
  background: #F6F8ED;
  flex-direction: column;
  overflow-x: hidden;
  // background-image: linear-gradient(58.2deg, rgba(40, 91, 212, 0.25) -3%, rgba(171, 53, 163, 0.25) 49.3%, rgba(255, 204, 112, 0.25) 97.7%);

  #forgot_pass {
    text-align: right;
    height: 20px;
  }

  /* This means that the style inside this block will be applied when the viewport width is 620px or smaller. */
  @media (max-width: 620px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 20px;
  }

  @media (max-height: 580px) {
    justify-content: flex-end;
  }

  @media (max-height: 480px) {
    justify-content: center;
    height: auto;
  }

  @media (max-height: 620px) {
    justify-content: center;
  }

  .landing_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100dvh;
  }

  .form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 100%;

    #go_back {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      margin-bottom: 20px;
    }

    &.slide-in {
      display: flex;
      animation: slideInFromRight 0.5s forwards;
    }

    &.slide-out {
      animation: slideOutToLeft 0.5s forwards;
    }

    @media (max-width: 620px) {
      justify-content: flex-start;
    }

    .form {
      max-width: 500px;
      width: 70%;
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (max-width: 1024px) {
        width: 90%;
      }

      @media (max-width: 720px) {
        width: 100%;
      }

      .tagline {
        font-size: 24px;
        font-weight: 300;
      }
    }

    .landing_form_bottom_text {
      margin: 10px 0px 30px 0px;
      text-align: center;
    }
  }

  #footer-absolute {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}